import { Routes } from '@angular/router';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { MenuItem } from './models/menu-item';
import { PermissionEnum } from './models/enums/permission-enum';
import { authGuard } from './guards/auth.guard';
import { noNeedLoginGuard } from './guards/no-need-login.guard';
import { AvailableSoonComponent } from './shared/components/available-soon/available-soon.component';

export const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    children: [
      {
        path: 'dashboard',
        data: {
          type: 'dashboard',
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'annual-investment-plans',
        data: {
          type: 'annual-investment-plans',
          permissions: [
            PermissionEnum.InvestmentPlanRead,
            PermissionEnum.InvestmentPlanRead,
            PermissionEnum.InvestmentPlanDelete,
          ],
        },
        loadChildren: () =>
          import(
            './routes/annual-investment-plans/annual-investment-plans.module'
          ).then((m) => m.AnnualInvestmentPlansModule),
      },
      {
        path: 'investment-portfolios',
        data: {
          type: 'investment-portfolios',
          permissions: [
            PermissionEnum.InvestmentPortfolioRead,
            PermissionEnum.InvestmentPortfolioWrite,
            PermissionEnum.InvestmentPortfolioSetActive,
            PermissionEnum.InvestmentPortfolioDelete,
          ],
        },
        loadChildren: () =>
          import(
            './routes/investment-portfolios/investment-portfolios.module'
          ).then((m) => m.InvestmentPortfoliosModule),
      },
      {
        path: 'investment-funds/investment-fund-management',
        data: {
          type: 'investment-funds',
          isSub: true,
          permissions: [
            PermissionEnum.InvestmentFundPaymentRead,
            PermissionEnum.InvestmentFundPaymentWrite,
            PermissionEnum.InvestmentFundPaymentDelete,
          ],
        },

        loadChildren: () =>
          import(
            './routes/manage-investment-fund/manage-investment-fund.module'
          ).then((m) => m.ManageInvestmentFundModule),
      },
      {
        path: 'investment-funds/investment-fund-statuses',
        data: {
          type: 'investment-funds',
          isSub: true,
          permissions: [
            PermissionEnum.InvestmentFundRead,
            PermissionEnum.InvestmentFundWrite,
            PermissionEnum.InvestmentFundDelete,
          ],
        },
        // component: AvailableSoonComponent,
        loadChildren: () =>
          import(
            './routes/investment-fund-statuses/investment-fund-statuses.module'
          ).then((m) => m.InvestmentFundStatusesModule),
      },
      {
        path: 'investment-requests/fixed-deposits',
        data: {
          type: 'investment-requests',
          isSub: true,
          permissions: [
            PermissionEnum.FixedInvestmentRequestRead,
            PermissionEnum.FixedInvestmentRequestWrite,
            PermissionEnum.FixedInvestmentRequestDelete,
          ],
        },
        loadChildren: () =>
          import('./routes/fixed-deposits/fixed-deposits.module').then(
            (m) => m.FixedDepositsModule
          ),
      },
      {
        path: 'investment-requests/government-bonds',
        data: {
          type: 'investment-requests',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'investment-requests/corporate-bonds',
        data: {
          type: 'investment-requests',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      // {
      //   path: 'payment-requests/fixed-deposits',
      //   data: {
      //     type: 'payment-requests',
      //     isSub: true,
      //   },
      //   component: AvailableSoonComponent,
      // },
      // {
      //   path: 'payment-requests/government-bonds',
      //   data: {
      //     type: 'payment-requests',
      //     isSub: true,
      //   },
      //   component: AvailableSoonComponent,
      // },
      // {
      //   path: 'payment-requests/corporate-bonds',
      //   data: {
      //     type: 'payment-requests',
      //     isSub: true,
      //   },
      //   component: AvailableSoonComponent,
      // },
      {
        path: 'investment-management/fixed-deposits',
        data: {
          type: 'investment-management',
          isSub: true,
          permissions: [
            PermissionEnum.FixedInvestmentRead,
            PermissionEnum.FixedInvestmentWrite,
            PermissionEnum.FixedInvestmentDelete,
          ],
        },
        loadChildren: () =>
          import(
            './routes/fixed-deposit-request-management/fixed-deposit-request-management.module'
          ).then((m) => m.FixedDepositRequestManagementModule),
      },
      {
        path: 'investment-management/government-bonds',
        data: {
          type: 'investment-management',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'investment-management/corporate-bonds',
        data: {
          type: 'investment-management',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'accounting/journals',
        data: {
          type: 'accounting',
          isSub: true,
          permissions: [PermissionEnum.JournalRead],
        },
        loadChildren: () =>
          import('./routes/journals/journals.module').then(
            (m) => m.JournalsModule
          ),
      },
      {
        path: 'accounting/general-ledgers',
        data: {
          type: 'accounting',
          isSub: true,
          permissions: [PermissionEnum.GeneralLedgerRead],
        },
        loadChildren: () =>
          import('./routes/general-ledgers/general-ledgers.module').then(
            (m) => m.GeneralLedgersModule
          ),
      },
      {
        path: 'accounting/account-charts',
        data: {
          type: 'accounting',
          isSub: true,
          permissions: [
            PermissionEnum.ChartOfAccountRead,
            PermissionEnum.ChartOfAccountWrite,
            PermissionEnum.ChartOfAccountSetActive,
          ],
        },
        loadChildren: () =>
          import('./routes/chart-of-accounts/chart-of-accounts.module').then(
            (m) => m.ChartOfAccountsModule
          ),
      },
      {
        path: 'reports/income-statement',
        data: {
          type: 'reports',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'reports/financial-reports',
        data: {
          type: 'reports',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'reports/capital-reports',
        data: {
          type: 'reports',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'reports/cashflow-reports',
        data: {
          type: 'reports',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'reports/profit-and-loss',
        data: {
          type: 'reports',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'reports/financial-statuses',
        data: {
          type: 'reports',
          isSub: true,
        },
        component: AvailableSoonComponent,
      },
      {
        path: 'settings/banks',
        data: {
          type: 'settings',
          isSub: true,
          permissions: [
            PermissionEnum.BankRead,
            PermissionEnum.BankWrite,
            PermissionEnum.BankDelete,
          ],
        },
        loadChildren: () =>
          import('./routes/banks/banks.module').then((m) => m.BanksModule),
      },
      {
        path: 'settings/asset-types',
        data: {
          type: 'settings',
          isSub: true,
          permissions: [
            PermissionEnum.AssetDelete,
            PermissionEnum.AssetRead,
            PermissionEnum.AssetWrite,
          ],
        },
        loadChildren: () =>
          import('./routes/asset-types/asset-types.module').then(
            (m) => m.AssetTypesModule
          ),
      },
      {
        path: 'settings/workflows',
        data: {
          type: 'settings',
          isSub: true,
          permissions: [
            PermissionEnum.WorkflowRead,
            PermissionEnum.WorkflowWrite,
            PermissionEnum.WorkflowDelete,
          ],
        },
        loadChildren: () =>
          import('./routes/workflows/workflows.module').then(
            (m) => m.WorkflowsModule
          ),
      },
      {
        path: 'settings/users',
        data: {
          type: 'settings',
          isSub: true,
          permissions: [
            PermissionEnum.UserAccountRead,
            PermissionEnum.UserAccountWrite,
            PermissionEnum.UserAccountResetPwd,
            PermissionEnum.UserAccountSetActive,
            PermissionEnum.UserAccountDelete,
          ],
        },
        loadChildren: () =>
          import('./routes/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'settings/roles-and-permissions',
        data: {
          type: 'settings',
          isSub: true,
          permissions: [
            PermissionEnum.RoleRead,
            PermissionEnum.RoleWrite,
            PermissionEnum.RoleSetActive,
            PermissionEnum.RoleDelete,
          ],
        },
        loadChildren: () =>
          import('./routes/roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'about-us',
        data: {
          type: 'about-us',
        },
        component: AvailableSoonComponent,
      },
    ],
  },
  {
    path: 'auth/callback',
    loadChildren: () =>
      import('./routes/nssf-callback/nssf-callback.module').then(
        (m) => m.NssfCallbackModule
      ),
  },
  {
    path: 'login',
    canActivate: [noNeedLoginGuard],
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginModule),
  },
];

export const MENUITEMS: Array<MenuItem> = routes[0].children!.map(
  (routeItem) => {
    return {
      route: routeItem.path,
      type: routeItem.data?.type,
      permissions: routeItem.data?.permissions as PermissionEnum[],
      title: routeItem.path.split('/').pop(),
      icon: routeItem.data?.icon,
      iconType: routeItem.data?.iconType,
      isSub: routeItem.data?.isSub,
    } as MenuItem;
  }
);
